import React from "react";
import * as Chakra from "@chakra-ui/react";
import { PageProps } from "gatsby";
import FadeTransition from "../components/transitions/fade-transition";
import Header from "../components/header";
import Footer from "../components/footer";
import "./layout.scss"

const Layout = ({ children, location }: PageProps): React.ReactElement => {

  return (
    <>
      <Chakra.Flex minH="100vh" flexDirection="column">
        <Header />
        <FadeTransition shouldChange={location.pathname}>
            <Chakra.Flex flexGrow={1} flexDirection="column" maxWidth="1200px" m="auto">
                {children}
            </Chakra.Flex>
        </FadeTransition>
        <Footer />
      </Chakra.Flex>
    </>
  );
};

export default Layout;
