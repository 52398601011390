import React from "react";
import * as Chakra from "@chakra-ui/react";
import Logo from "../images/icon.png";
import { GatsbyImageSVGFallback } from "./gatsby-image-svg-fallback";
import {Link} from "gatsby";

const Header: React.FC = () => {
  //const { isOpen, onOpen, onClose } = Chakra.useDisclosure();
  return (
    <Chakra.Flex
      pos="sticky"
      zIndex="100"
      top="0"
      as="header"
      height="100px"
      width="100%"
      transition="1s ease-out"
      display="flex"
      alignItems="center"
      justifyContent="center"
      css={{ backdropFilter: "blur(12px)" }}
    >
        <Chakra.Flex width="100%" alignItems="center" justifyContent="space-between" maxWidth="1260px" px="40px" m="auto">
            <Chakra.Flex width="100%" justifyContent="flex-start" alignItems="center">
                <Link to="/">
                    <GatsbyImageSVGFallback width="60px" height="60px" mr="40px" src={Logo} />
                </Link>
            </Chakra.Flex>
            <Chakra.HStack width="100%" justifyContent="flex-end" alignItems="center" spacing="20px">
                <Chakra.Button shadow="base" variant="ghost" as={Link} to="/">Home</Chakra.Button>
                <Chakra.Button shadow="base" variant="ghost" as={Link} to="/guide">Guides</Chakra.Button>
            </Chakra.HStack>
        </Chakra.Flex>
    </Chakra.Flex>
  );
};

export default Header;
